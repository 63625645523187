import React, { useEffect } from 'react';
import Config from './lib/config';

import './App.scss';
import 'react-block-ui/style.css';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Snow } from 'components/Snow';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  Admin,
  Resource,
  //ListGuesser,
  //ShowGuesser,
  resolveBrowserLocale,
} from 'react-admin';

import './lib/security';
import authProvider from './lib/authProvider';
import dataProvider from './lib/dataProvider';
import i18nProvider from './lib/i18nProvider';
import * as async from './lib/async';

//import InvoiceIcon from '@material-ui/icons/Receipt';
//import JobIcon from '@material-ui/icons/LocalShipping';
import JobIcon from '@material-ui/icons/LocalShipping';
//import CalendarIcon from '@material-ui/icons/EventOutlined';
//import ItemIcon from '@material-ui/icons/ToysOutlined';
//import QuoteIcon from '@material-ui/icons/FormatQuoteOutlined';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';
//import AddressIcon from '@material-ui/icons/ContactsOutlined';
//import ProfileIcon from '@material-ui/icons/Person';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

import 'lib/install';

import { MyTheme } from './theme/MyTheme';
import { MyLayout } from './theme/MyLayout';
import { MyLogoutButton } from './theme/MyLogout';

import history from './lib/history';
// doesn't work in multi-tenant cross-domain apps

import customRoutes from './routes';
import customReducers from './reducers';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Login = async.Page('Login');
const Dashboard = async.Page('Dashboard');
//const Profile = async.Resource('Profile');

//const Quote = async.Resource('Quote');
//const QuoteJob = async.Resource('QuoteJob');
//const QuoteItem = async.Resource('QuoteItem');
const Invoice = async.Resource('Invoice');
const InvoiceJob = async.Resource('InvoiceJob');
//const InvoiceItem = async.Resource('InvoiceItem');

//const Contact = async.Resource('Contact');
//const Address = async.Resource('Address');
//const Place = async.Resource('Place');

export const App = () => {
  console.log('App()');
  //  if (typeof window !== 'undefined') {
  //    const { hash = '', pathname = '', search = '' } = window.location;
  //    const basepath = window.REACT_APP_BASE_PATH || '/';
  //    useEffect(() => {
  //      console.log('App() landing', { pathname, hash, search });
  //      if (pathname !== basepath) {
  //        const hashify = '/#' + pathname + search;
  //        console.log('App() landing hashify', { hashify });
  //        window.location = hashify;
  //      }
  //    }, [hash, search, pathname]);
  //    if (pathname !== basepath) {
  //      console.log('App() abort: do not render until we have a has', { hash });
  //      return 'Please wait...';
  //    }
  //  }

  return (
    <Elements stripe={stripePromise}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={MyTheme}>
          <CssBaseline />

          <Admin
            history={history}
            // doesn't work in multi-tenant cross-domain apps

            theme={MyTheme}
            layout={MyLayout}
            logout={MyLogoutButton}
            loginPage={Login}
            dashboard={Dashboard}
            initialState={() => ({ snow: { flakes: 0 }, admin: {} })}
            authProvider={authProvider(Config)}
            dataProvider={dataProvider(Config)}
            i18nProvider={i18nProvider}
            locale={resolveBrowserLocale()}
            customRoutes={customRoutes}
            customReducers={customReducers}
          >
            <Snow />

            <Resource
              name="tb.invoice"
              options={{ label: 'Orders' }}
              icon={InvoiceIcon}
              show={Invoice.Show}
              list={Invoice.List}
            />
            <Resource name="tb.invoice.component" />
            <Resource name="tb.invoice.signature" />
            <Resource name="tb.invoice.image" />
            <Resource name="tb.invoice.audio" />
            <Resource name="tb.invoice.video" />
            <Resource name="tb.invoice.coupon" />
            <Resource name="tb.invoice.discount" />
            <Resource name="tb.invoice.refund" />
            <Resource name="tb.invoice.transaction" />
            <Resource name="tb.invoice.billination" />
            <Resource name="tb.invoice.shipping" />
            <Resource name="tb.invoice.destination" />
            <Resource
              name="tb.invoice.job"
              options={{ label: 'Jobs' }}
              icon={JobIcon}
              show={InvoiceJob.Show}
              list={InvoiceJob.List}
            />
            <Resource
              name="tb.invoice.item"
              //options={{label: "Products"}}
              //icon={ItemIcon}
              //show={InvoiceItem.Show}
              //list={InvoiceItem.List}
            />
            <Resource name="tb.invoice.item.delivery" />
            <Resource name="tb.invoice.item.commitment" />

            {/*
            <Resource
              name="tb.quote"
              options={{ label: 'Quotes' }}
              icon={QuoteIcon}
              show={Quote.Show}
              list={Quote.List}
            />
            <Resource name="tb.quote.component" />
            <Resource name="tb.quote.signature" />
            <Resource name="tb.quote.image" />
            <Resource name="tb.quote.audio" />
            <Resource name="tb.quote.video" />
            <Resource name="tb.quote.job" />
            <Resource name="tb.quote.item" />
            <Resource name="tb.quote.coupon" />
            <Resource name="tb.quote.discount" />
            <Resource name="tb.quote.billination" />
            <Resource name="tb.quote.destination" />
  */}

            {/*
                  <Resource name="tb.profile" 
                      options={{label: "Account Profile"}} 
                      icon={ProfileIcon} 
                      show={Profile.Show} 
                      list={Profile.List} 
                      sort={{ field: 'name', order: 'ASC' }}
                  />
                  <Resource name="tb.profile.address" 
                      options={{label: "Address Book"}} 
                      icon={AddressIcon} 
                      show={Address.Show} 
                      list={Address.List} 
                      sort={{ field: 'name', order: 'ASC' }}
                  />
                  */}

            <Resource name="tb.page" />
            <Resource name="tb.page.album" />
            <Resource name="tb.page.attachment" />
            <Resource name="tb.page.audio" />
            <Resource name="tb.page.brand" />
            <Resource name="tb.page.brochure" />
            <Resource name="tb.page.collection" />
            <Resource name="tb.page.colour" />
            <Resource name="tb.page.comment" />
            <Resource name="tb.page.download" />
            <Resource name="tb.page.faq" />
            <Resource name="tb.page.file" />
            <Resource name="tb.page.image" />
            <Resource name="tb.page.model" />
            <Resource name="tb.page.price" />
            <Resource name="tb.page.review" />
            <Resource name="tb.page.scrap" />
            <Resource name="tb.page.size" />
            <Resource name="tb.page.video" />

            <Resource name="tb.product" />
            <Resource name="tb.product.album" />
            <Resource name="tb.product.attachment" />
            <Resource name="tb.product.audio" />
            <Resource name="tb.product.brand" />
            <Resource name="tb.product.brochure" />
            <Resource name="tb.product.collection" />
            <Resource name="tb.product.colour" />
            <Resource name="tb.product.comment" />
            <Resource name="tb.product.download" />
            <Resource name="tb.product.faq" />
            <Resource name="tb.product.file" />
            <Resource name="tb.product.image" />
            <Resource name="tb.product.model" />
            <Resource name="tb.product.price" />
            <Resource name="tb.product.review" />
            <Resource name="tb.product.scrap" />
            <Resource name="tb.product.size" />
            <Resource name="tb.product.video" />

            <Resource name="tb.service" />
            <Resource name="tb.service.option" />
          </Admin>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Elements>
  );
};

export default App;
