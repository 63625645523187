import { createTheme } from '@material-ui/core/styles';
//import grey from '@material-ui/core/colors/grey';
//import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export const primaryColour = process.env.REACT_APP_primaryColour;
export const secondaryColour = process.env.REACT_APP_secondaryColour;
export const lightTextColour = process.env.REACT_APP_lightTextColour;

console.log('THEME PROPS', { primaryColour, secondaryColour, lightTextColour });

// create blank theme to expose breakpoints,
const theme = createTheme({
  palette: {
    primary: {
      //main: green[900],
      main: primaryColour,
    },
    secondary: {
      //main: green[900],
      main: secondaryColour,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  components: {
    RaUserMenu: {
      styleOverrides: {
        width: 300,
        minWidth: 300,
        background: 'red',
      },
    },
  },
});

// then overwrite theme and we can use breakpoints
// https://stackoverflow.com/a/56320113
export const MyTheme = {
  ...theme,

  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        background: primaryColour, // + '!important', //theme.palette.primary, //primaryColour,
        color: lightTextColour, // + '!important',
        // Name of the rule
        //color: 'white', // Some CSS
        '&:hover': {
          background: secondaryColour + '!important', //theme.palette.secondary, //secondaryColour & ' !important',
          //color: 'red !important',
        },
      },
    },
    MuiToolbar: {
      root: {
        maxWidth: '800px',
        width: '100%',
        margin: 'auto',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%', //background: 'red',
      },
    },
    MuiCard: {
      root: {
        width: '100%',
        marginBottom: '20px',
      },
    },
    MuiCardContent: {
      root: {
        width: '100%', //background: 'red',
      },
    },
    MuiCardActionArea: {
      root: {
        width: '100%', //background: 'red',
      },
    },
    MuiListItemIcon: {
      root: {
        marginLeft: '10px',
      },
    },
    //RaLabeled: {
    //  root: {
    //    width: '100%', //background: 'red',
    //  },
    //},
    RaReferenceField: {
      link: {
        color: '#fff',
      },
    },
    RaShow: {
      card: {
        boxShadow: 'none',
      },
    },
    RaCardContentInner: {
      root: {
        border: 0,
      },
    },
    RaSingleFieldList: {
      root: {
        width: '100%', //background: 'red',
      },
      link: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
  },
};

export default MyTheme;
