import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { MyUserMenu } from './MyUserMenu';
import logo from 'theme/MyLogo';
import { Link } from 'react-router-dom'; // import Link from '@material-ui/core/Link';

//import Logo from './Logo.svg';

export const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: '40px',
    background: 'transparent',
    //background: secondaryColour,
  },
});

export const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar userMenu={<MyUserMenu />} {...props}>
      <Link to="/">
        <img src={logo} alt="logo" className={classes.logo} />
      </Link>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {/* <Logo /> */}
      <span className={classes.spacer} />
    </AppBar>
  );
};
