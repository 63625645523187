import React, { forwardRef } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  useTranslate,
  useQueryWithStore, // UseDataProvider,
  UserMenu,
  MenuItemLink,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Token from 'lib/token';
import { setSnow } from 'reducers/snowReducer';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
//import MenuItem from '@material-ui/core/MenuItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemText from '@material-ui/core/ListItemText';
import { InstallPromptMenuItem } from 'components/InstallPrompt';

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
  // The key `root` provided to the classes prop is not implemented in undefined.
  // You can only override one of the following: user,userButton,avatar.
  //root: {
  //  width: 300,
  //  background: 'red',
  //  border: 'blue solid 1px',
  //},
});

const MyCustomIcon = connect()(
  (
    // eslint-disable-next-line
    { img, dispatch }
  ) => {
    const classes = useStyles();

    return <Avatar className={classes.avatar} src={img} />;
  }
);

export const SettingsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/settings"
      primaryText={translate('ra.menu.settings', { _: 'Settings' })}
      leftIcon={<SettingsIcon />}
      onClick={onClick} // Close the menu on click
    />
  );
});

export const ShortcutsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/contact"
      primaryText={translate('ra.menu.settings', { _: 'Contact' })}
      leftIcon={<EmailIcon />}
      onClick={onClick} // Close the menu on click
    />
  );
});

export const SnowControl = connect(
  ({ snow }) => ({
    snow,
  }),
  {
    setSnow,
  }
)(({ snow, setSnow }) => {
  const labels = {
    0: 'Snow Off',
    1: "Won't settle",
    2: 'School is out!',
    3: 'White Christmas',
    4: 'Build a snowman',
  };
  const getLabel = (value) => labels[value];
  const icons = {
    0: SentimentVeryDissatisfiedIcon,
    1: SentimentDissatisfiedIcon,
    2: SentimentSatisfiedIcon,
    3: SentimentSatisfiedAltIcon,
    4: SentimentVerySatisfiedIcon,
  };
  const getIcon = (value) => icons[value];
  const Icon = getIcon(snow.flakes);
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ marginTop: 30 }}> </div>
      <div style={{ padding: '0 10px' }}>
        <AcUnitIcon />
      </div>
      <div style={{ padding: '0 10px' }}>
        <Typography style={{ fontSize: 12, textDecoration: 'underline' }}>
          Snow level
        </Typography>
      </div>
      <div style={{ padding: '0 10px' }}>
        <Slider
          defaultValue={snow.flakes}
          getAriaLabel={getLabel}
          getAriaValueText={getLabel}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="off"
          step={1}
          marks
          min={0}
          max={4}
          onChange={(event, value) => setSnow({ ...snow, flakes: value })}
        />
      </div>
      <div style={{ padding: '0 10px' }}>
        <Typography style={{ fontSize: 12, fontWeight: 900 }}>
          {getLabel(snow.flakes)}
        </Typography>
      </div>
      <div style={{ padding: '0 10px' }}>
        <Icon />
      </div>
      <div style={{ marginTop: 20 }}> </div>
    </div>
  );
});

export const MyUserMenu = withStyles({ root: { width: 300 } })(
  ({
    //onMenuClick = () => {},
    ...props
  }) => {
    const classes = useStyles();

    // Get user from state, this should be enough
    const userFromState = useSelector((state) => state.user);
    // But can't figure out how to dispatch yet, so, do it the long way
    const { u: userId } = Token.get();
    // Console.log('MyCustomIcon', {userFromState, userId, props});
    const { loading, error, data } = useQueryWithStore({
      type: 'getOne',
      resource: 'profile',
      payload: { id: userId },
    });

    if (loading) return ''; // <Loading />;
    if (error) return ''; // <Error />;
    if (!data) return null;

    const md5 = window.CryptoJS.MD5(data.email).toString();
    const img = `https://www.gravatar.com/avatar/${md5}`;

    console.log('MyCustomIcon state', {
      data,
      md5,
      img,
      userFromState,
      loading,
      error,
    });

    return (
      <UserMenu classes={classes} icon={<MyCustomIcon img={img} />} {...props}>
        <InstallPromptMenuItem />
        {/* <MenuItem>Hi {data.name}</MenuItem> */}
        {/* <SettingsMenu /> */}
        <ShortcutsMenu />
        <SnowControl />
      </UserMenu>
    );
  }
);
