const SET_SNOW = 'SET_SNOW';

const initialState = {
  flakes: 0,
};

export default (previousState = initialState, { type, payload }) => {
  if (type === SET_SNOW) {
    return payload;
  }
  return previousState;
};

export const setSnow = (payload) => {
  return {
    type: SET_SNOW,
    payload,
  };
};
