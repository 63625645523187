import reschedule from './rescheduleReducer';
import feedback from './feedbackReducer';
import user from './userReducer';
import snow from './snowReducer';
//import buy from './buyReducer';

export default {
  reschedule,
  feedback,
  user,
  snow,
  //buy,
};
