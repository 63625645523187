import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Snowfall from 'react-snowfall';
export const weatherApi =
  'https://api.weatherapi.com/v1/current.json?key=988f6f6fec0d4ff0a4580252211310&q=London&aqi=no';

// see,
// https://api.weatherapi.com/v1/current.json?key=988f6f6fec0d4ff0a4580252211310&q=London&aqi=no
//  {
//     "location":{
//        "name":"London",
//        "region":"City of London, Greater London",
//        "country":"United Kingdom",
//        "lat":51.52,
//        "lon":-0.11,
//        "tz_id":"Europe/London",
//        "localtime_epoch":1637840747,
//        "localtime":"2021-11-25 11:45"
//     },
//     "current":{
//        "last_updated_epoch":1637839800,
//        "last_updated":"2021-11-25 11:30",
//        "temp_c":6,
//        "temp_f":42.8,
//        "is_day":1,
//        "condition":{
//           "text":"Sunny",
//           "icon":"//cdn.weatherapi.com/weather/64x64/day/113.png",
//           "code":1000
//        },
//        "wind_mph":5.6,
//        "wind_kph":9,
//        "wind_degree":360,
//        "wind_dir":"N",
//        "pressure_mb":1018,
//        "pressure_in":30.06,
//        "precip_mm":0,
//        "precip_in":0,
//        "humidity":65,
//        "cloud":0,
//        "feelslike_c":2.9,
//        "feelslike_f":37.1,
//        "vis_km":10,
//        "vis_miles":6,
//        "uv":2,
//        "gust_mph":12.5,
//        "gust_kph":20.2
//     }
//  }

export const Snow = () => {
  const snow = useSelector((state) => state.snow);

  const [isActuallySnowing, setActuallySnowing] = useState(false);
  useEffect(async () => {
    const response = await fetch(weatherApi);
    const current = response?.current?.condition?.text || '';
    const isSnowing = !!current.match(/snow/gi);
    setActuallySnowing(isSnowing);
  }, []);

  const showSnow = !!snow.flakes || isActuallySnowing;

  return (
    !!showSnow && (
      <Snowfall
        // Changes the snowflake color
        //color="red"
        // Applied to the canvas element
        style={{
          height: '100vw',
          width: '100vw',
          position: 'absolute',
          //background: '#f7f7f7',
          zIndex: 999999,
          pointerEvents: 'none',
        }}
        // Controls the number of snowflakes that are created (default 150)
        snowflakeCount={snow.flakes * 50}
      />
    )
  );
};
