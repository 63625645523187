import * as React from 'react';
import { Route } from 'react-router-dom';
import * as async from 'lib/async';

const Forgot = async.Page('Forgot'); //import Forgot from 'views/Forgot';
const First = async.Page('First'); //import Forgot from 'views/Forgot';
const Reset = async.Page('Reset'); //import Reset from 'views/Reset';
const Register = async.Page('Register');
const Install = async.Page('Install');

const Feedback = async.Page('Feedback');
const Contact = async.Page('Contact');
//const Help = async.Page('Help');
// const FAQs = async.Page('FAQs');
const Blog = async.Page('Blog');

export default [
  <Route exact path="/first" component={First} noLayout key="first" />,
  <Route exact path="/forgot" component={Forgot} noLayout key="forgot" />,
  <Route exact path="/reset" component={Reset} noLayout key="reset" />,
  <Route exact path="/register" component={Register} noLayout key="register" />,
  <Route exact path="/install" component={Install} key="install" />,

  // dirty hack is dirty, for backwards compatibility
  <Route exact path="/client/forgot" component={Reset} noLayout key="client" />,
  /* this is the only hack, no need to create redirects */

  // business routes
  <Route exact path="/feedback" component={Feedback} key="feedback" />,
  // <Route exact path="/help" component={Help}  key="help" />,
  // <Route exact path="/faqs" component={FAQs} key="faqs" />,
  <Route exact path="/contact" component={Contact} key="contact" />,
  <Route exact path="/blog" component={Blog} key="blog" />,
];
